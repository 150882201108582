<template>
  <h2 class="font-bold text-title mb-5">
    404
  </h2>
</template>

<script>
export default {
  name: 'NotFound',
  components: {},
  data: () => ({})
};
</script>

<style lang="scss" scoped></style>

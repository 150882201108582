<template>
  <h2 class="font-bold text-title mb-5">
    Statistiques
  </h2>
  <p>Disponible prochainement</p>
</template>

<script>
export default {
  name: 'Statistics',
  components: {},
  data: () => ({})
};
</script>

<style lang="scss" scoped></style>
